// src/pages/SignUp.js
import React, { useEffect, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux'; 
import {  toast } from 'react-toastify';
import './SignUp.css';
import { registerThunk } from '../../Redux/reducer/RegisterReducer';

const SignUp = () => {

  const dispatch = useDispatch();

  const registerUserPost = useSelector((state) => state.registerUserPost); 



  // State variables for input fields
  const [fullName, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reTypePassword, setreTypePassword] = useState('');

  useEffect(() => {
    if(registerUserPost.registerStatus === "idle"){
      toast.success("Your account is created")
    }
    if(registerUserPost.registerStatus === "error"){
      toast.error("Something Error")
    }
  }, [registerUserPost])
  





  const handleSubmit = () => {
    // Check if passwords match
    // if (password !== reTypePassword) {
    //   console.error("Passwords do not match");
    //   return;
    // }

    dispatch(registerThunk({ fullName, email, password, reTypePassword })) 



    // Clear the input fields after submission
    // setName('');
    // setEmail('');
    // setPassword('');
    // setreTypePassword('');
  };

  return (
    <div className="signup-container">
      <h2>Create an Account</h2>
      <label htmlFor="name">Full Name</label>
      <input
        type="text"
        id="name"
        value={fullName} // Set the value from state
        onChange={(e) => setName(e.target.value)} // Update state on change
        placeholder="Enter your full name"
        required
      />

      <label htmlFor="email">Email</label>
      <input
        type="email"
        id="email"
        value={email} // Set the value from state
        onChange={(e) => setEmail(e.target.value)} // Update state on change
        placeholder="Enter your email"
        required
      />

      <label htmlFor="password">Password</label>
      <input
        type="password"
        id="password"
        value={password} // Set the value from state
        onChange={(e) => setPassword(e.target.value)} // Update state on change
        placeholder="Enter your password"
        required
      />

      <label htmlFor="reTypePassword">Retype Password</label>
      <input
        type="password"
        id="reTypePassword"
        value={reTypePassword} // Set the value from state
        onChange={(e) => setreTypePassword(e.target.value)} // Update state on change
        placeholder="Retype your password"
        required
      />

      <button type="button" className="submit-button" onClick={handleSubmit}>
        Sign Up
      </button>
    </div>
  );
};

export default SignUp;
