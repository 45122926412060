// src/pages/UserDashboard.js
import React from 'react';
import { useSelector } from 'react-redux';
import './UserDashboard.css'; // Import CSS for styling

const UserDashboard = () => {
  // Assuming user data is stored in Redux after login
  const user = useSelector((state) => state.loginUserPost.loginData.user); // Replace with your state structure

  return (
    <div className="dashboard-container">
      <h2>Welcome, {user?.fullName || 'User'}!</h2>

      <div className="user-info">
        <h3>Your Profile</h3>
        <ul>
          <li><strong>Full Name:</strong> {user?.fullName}</li>
          <li><strong>Email:</strong> {user?.email}</li>
        </ul>
      </div>

      <div className="dashboard-actions">
        <h3>Dashboard Actions</h3>
        <button className="action-button">View Courses</button>
        <button className="action-button">Edit Profile</button>
        <button className="action-button">Logout</button>
      </div>
    </div>
  );
};

export default UserDashboard;
