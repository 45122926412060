import { combineReducers } from '@reduxjs/toolkit';

import RegisterReducer from "../reducer/RegisterReducer";
import LoginReducer from "../reducer/LoginReducer"


const rootReducer = combineReducers({
    registerUserPost : RegisterReducer,
    loginUserPost : LoginReducer

})



export default rootReducer;