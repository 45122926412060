import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const loginThunk = createAsyncThunk("login/post", async ({ email, password }, { rejectWithValue }) => {

    
    try {
        const config = { headers: { "Content-type": "application/json" } };
        const response = await axios.post("/api/v1/login", { email, password }, config);
        console.log(response);
        return response.data;
    } catch (error) {
        if (!error.response) {
            // Assuming you want to handle network errors differently
            return rejectWithValue("Network error: Unable to reach the server");
        }
        return rejectWithValue(error.response.data);
    }
});

const loginSlice = createSlice({
    name: "login",
    initialState: {
        loginData: [],
        loginIsAuthenticated: false,
        loginStatus: "",
        loginErrorData: []
    },
    reducers: {
        resetloginState: (state) => {
            state.loginData = [];
            state.loginStatus = "";
        },
        resetloginError: (state) => {
            state.loginErrorData = [];
            state.loginStatus = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.pending, (state) => {
                state.loginStatus = "loading";
                state.loginIsAuthenticated = false;
            })
            .addCase(loginThunk.fulfilled, (state, action) => {
                state.loginData = action.payload;
                state.loginStatus = "idle";
                state.loginIsAuthenticated = true;
            })
            .addCase(loginThunk.rejected, (state, action) => {
                state.loginStatus = "error";
                state.loginIsAuthenticated = false;
                state.loginErrorData = action.payload;
            });
    }
});

export const { resetloginState, resetloginError } = loginSlice.actions;
export default loginSlice.reducer;
