import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const registerThunk = createAsyncThunk("register/post", async ({ fullName, email, password, reTypePassword }, { rejectWithValue }) => {

    console.log({ fullName, email, password, reTypePassword })
    try {
        const config = { headers: { "Content-type": "application/json" } };
        const response = await axios.post("/api/v1/create/user", { fullName, email, password, reTypePassword }, config);
        console.log(response);
        return response.data;
    } catch (error) {
        if (!error.response) {
            // Assuming you want to handle network errors differently
            return rejectWithValue("Network error: Unable to reach the server");
        }
        return rejectWithValue(error.response.data);
    }
});

const registerSlice = createSlice({
    name: "register",
    initialState: {
        registerData: [],
        registerIsAuthenticated: false,
        registerStatus: "",
        registerErrorData: []
    },
    reducers: {
        resetregisterState: (state) => {
            state.registerData = [];
            state.registerStatus = "";
        },
        resetregisterError: (state) => {
            state.registerErrorData = [];
            state.registerStatus = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerThunk.pending, (state) => {
                state.registerStatus = "loading";
                state.registerIsAuthenticated = false;
            })
            .addCase(registerThunk.fulfilled, (state, action) => {
                state.registerData = action.payload;
                state.registerStatus = "idle";
                state.registerIsAuthenticated = true;
            })
            .addCase(registerThunk.rejected, (state, action) => {
                state.registerStatus = "error";
                state.registerIsAuthenticated = false;
                state.registerErrorData = action.payload;
            });
    }
});

export const { resetregisterState, resetregisterError } = registerSlice.actions;
export default registerSlice.reducer;
