// src/pages/SignIn.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './SignIn.css';
import { loginThunk } from '../../Redux/reducer/LoginReducer'; // Assuming you have a login reducer

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const loginUserPost = useSelector((state) => state.loginUserPost); // Assuming this slice of state exists in your root reducer

  // State variables for input fields
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (loginUserPost.loginStatus === "idle") {
      toast.success("You are successfully logged in");
      navigate("/dashboard")
        // dispatch(resetloginState())
    }
    if (loginUserPost.loginStatus === "error") {
      toast.error("Invalid login credentials");
    }
  }, [loginUserPost,navigate]);

  const handleSubmit = () => {
    // Dispatch the login action
    dispatch(loginThunk({ email, password }));

    // Clear the input fields after submission (optional)
    // setEmail('');
    // setPassword('');
  };

  return (
    <div className="signin-container">
      <h2>Sign In</h2>

      <label htmlFor="email">Email</label>
      <input
        type="email"
        id="email"
        value={email} // Set the value from state
        onChange={(e) => setEmail(e.target.value)} // Update state on change
        placeholder="Enter your email"
        required
      />

      <label htmlFor="password">Password</label>
      <input
        type="password"
        id="password"
        value={password} // Set the value from state
        onChange={(e) => setPassword(e.target.value)} // Update state on change
        placeholder="Enter your password"
        required
      />

      <button type="button" className="submit-button" onClick={handleSubmit}>
        Sign In
      </button>
    </div>
  );
};

export default SignIn;
