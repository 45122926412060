
import './App.css';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import Navbar from './Component/navbar/Navbar';
import Home from './Component/Home/Landing';
import SignUp from './Component/User/Signup/Signup';
import Login from './Component/User/Login/Login';
import UserDashboard from './Component/User/Dashboard/UserDashboard';

function App() {
  return (
    <>
      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element= {<Home/>} />
          <Route path="/signup" element= {<SignUp/>} />
          <Route path="/login" element= {<Login/>} />
          <Route path="/dashboard" element= {<UserDashboard/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
