// src/pages/Home.js
import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      {/* Welcome Banner */}
      <section className="welcome-banner">
        <h1>Welcome to Pathsala</h1>
        <p>Your path to knowledge begins here</p>
        <button className="cta-button">Explore Courses</button>
      </section>

      {/* Featured Courses Section */}
      <section className="featured-courses">
        <h2>Popular Courses</h2>
        <div className="courses-grid">
          <div className="course-card">
            <h3>Web Development</h3>
            <p>Learn HTML, CSS, JavaScript, and more</p>
          </div>
          <div className="course-card">
            <h3>Data Science</h3>
            <p>Master data analysis and machine learning</p>
          </div>
          <div className="course-card">
            <h3>Digital Marketing</h3>
            <p>Grow your brand in the digital age</p>
          </div>
          <div className="course-card">
            <h3>Graphic Design</h3>
            <p>Unleash your creativity with design tools</p>
          </div>
        </div>
      </section>

      {/* About Pathsala Section */}
      <section className="about-section">
        <h2>About Pathsala</h2>
        <p>
          Pathsala is an e-learning platform designed to provide quality education to everyone, everywhere.
          We offer a wide range of courses, designed and taught by industry experts.
        </p>
        <button className="cta-button">Learn More</button>
      </section>
    </div>
  );
};

export default Home;
